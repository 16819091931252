<!-- 会员服务-车接车送 -->

<template>
  <div class="carmeet main-cnt">
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 280px)" :apiName="VipApi.getCarmeetLists"
        :columns="tableColumns" @edit="openDialog" @onDetails="onDetails" @statusChange="statusChange">
        <template #operate>
          <el-button type="primary" @click="openDialog" v-if="authData.indexOf('n_0TvMre9uwDEOLiyqF12QxOW0UM') != -1">
            新增路线</el-button>
        </template>

        <template #telephone="{ row }">
          <div class="flex align-center">
            <img src="@/assets/img/phone.png" style="width: 16px;height: 16px;" />
            <div>{{ row.mcr_tel }}</div>
          </div>
        </template>

        <template #responsible="{ row }">
          <div class="flex align-center text-hidden">
            <div v-for="(item, i) in row.responsible_user" :key="i" class="">
              <span>{{ item.u_name }}</span>
              <span v-if="i !== (row.responsible_user.length - 1)">,</span>
            </div>
          </div>
        </template>

        <template #unitPrice="{ row }">
          <div>{{ row.mcr_people_money }}元/人</div>
        </template>

        <template #charterCar="{ row }">
          <div>{{ row.mcr_chartered_money }}元/车</div>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑路线 -->
    <w-dialog ref="dialogRef" class="carmeet-add-dialog" :title="currentRow.mcr_id ? '编辑路线' : '新增路线'" width="50%"
      btnWidth="140px" top="10vh" :confirmText="currentRow.mcr_id ? '确认提交' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="路线名称" prop="mcr_name">
              <el-input v-model="ruleForm.mcr_name" clearable placeholder="请输入路线名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="运营时间" prop="time">
              <el-time-picker v-model="ruleForm.time" is-range range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" value-format="HH:mm" format="HH:mm" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="接车单价" prop="mcr_people_money">
              <el-input v-model="ruleForm.mcr_people_money" type="number" autocomplete="off" placeholder="请输入接车单价"
                clearable oninput="if(value<0)value=0"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="包车单价" prop="mcr_chartered_money">
              <el-input v-model="ruleForm.mcr_chartered_money" type="number" autocomplete="off" placeholder="请输入包车单价"
                clearable oninput="if(value<0)value=0"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="咨询电话" prop="mcr_tel">
              <el-input v-model="ruleForm.mcr_tel" clearable placeholder="请输入咨询电话"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="负责人" prop="m_company">
              <el-select clearable filterable remote reserve-keyword v-model="staffKeywords" placeholder="请输入员工姓名或手机号"
                remote-show-suffix :remote-method="searchManage" @change="changeManage">
                <el-option v-for="(item,index) in manageList" :key="index" :label="item.u_name + '-' + item.u_tel"
                  :value="item.u_id">
                  <span style="float: left">{{ item.u_name }}</span>
                  <span style="float: right; font-size: 13px">{{item.u_tel }}</span>
                </el-option>
              </el-select>
              <div class="contents flex">
                <div class="active-skin" v-for="(item,i) in onfirmManage" :key="i">
                  <div class="text-hidden s-m-r">{{item.u_name}}</div>
                  <div class="cursor-pointer" @click="delManage(i)">x</div>
                </div>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="mcr_route_desc" label="路线描述">
              <el-input type="textarea" rows="3" placeholder="请输入路线描述" clearable v-model.trim="ruleForm.mcr_route_desc">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="mcr_rule_desc" label="规则说明">
              <el-input type="textarea" rows="3" placeholder="请输入规则说明" clearable v-model.trim="ruleForm.mcr_rule_desc">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 路线详情 -->
    <w-dialog ref="infoDialogRef" title="路线详情" width="50%" btnWidth="140px" top="10vh" :hideFooter="true">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">路线名称</div>
            <div class="infor-content">{{ detailInfo.mcr_name }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">运营时间</div>
            <div class="infor-content">{{ detailInfo.mcr_run_stime }}-{{ detailInfo.mcr_run_etime }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">接车单价</div>
            <div class="infor-content">{{ detailInfo.mcr_people_money }}元/人</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">包车单价</div>
            <div class="infor-content">{{ detailInfo.mcr_chartered_money }}元/车</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">咨询电话</div>
            <div class="infor-content">{{ detailInfo.mcr_tel }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="flex">
            <div class="infor-title">负责人</div>
            <div class="infor-content">
              <div class="flex align-center">
                <div v-for="(item, i) in detailInfo.responsible_user" :key="i" class="">
                  <span>{{ item.u_name }}</span>
                  <span v-if="i !== (detailInfo.responsible_user.length - 1)">、</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="describe-title">路线描述</div>
          <textarea v-model="detailInfo.mcr_route_desc" class="infor-content rule-text" readonly :rows="3"></textarea>
        </el-col>
        <el-col :span="24">
          <div class="describe-title">规则描述</div>
          <textarea v-model="detailInfo.mcr_rule_desc" class="infor-content rule-text" readonly :rows="3"></textarea>
        </el-col>
      </el-row>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch, } from "vue";
  import { VipApi, BasicApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import { checkStr, unique, } from "@/utils/common.js";

  // 权限
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格对象 */
  const tableRef = ref(null);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "mcr_id",
      label: "线路ID",
    },
    {
      prop: "mcr_name",
      label: "线路名称",
      minWidth: 150,
    },
    {
      type: "connect",
      prop: "mcr_run_stime",
      prop2: "mcr_run_etime",
      connectText: "-",
      label: "运营时段",
      minWidth: 120,
    },
    {
      type: "customRender",
      prop: "unitPrice",
      label: "接车单价",
      minWidth: 100,
    },
    {
      type: "customRender",
      prop: "charterCar",
      label: "包车价格",
      minWidth: 100,
    },
    {
      type: "customRender",
      prop: "telephone",
      label: "咨询电话",
      minWidth: 150,
    },
    {
      prop: "mcr_route_desc",
      label: "线路描述",
      minWidth: 200,
    },
    {
      prop: "mcr_rule_desc",
      label: "规则说明",
      minWidth: 200,
    },
    {
      type: "switch",
      prop: "mcr_status",
      label: "状态",
      activeText: " ",
      inactiveText: " ",
      token: "n_ZuspqDuWtrfTjTMsZLDq8wXn7f",
    },
    {
      type: "obj",
      objKey: "u_name",
      prop: "creater",
      label: "创建人",
    },
    {
      prop: "mcr_update_time",
      label: "创建时间",
      minWidth: 150,
    },
    {
      type: "customRender",
      prop: "responsible",
      label: "负责人",
      minWidth: 220,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 190,
      bottons: [
        {
          name: "详情",
          action: "onDetails",
          className: "theme-font-btn",
          token: "n_kVvzPDQg9JbkZM0P5XiOveRRCw",
        },
        {
          name: "编辑",
          action: "edit",
          className: "theme-font-btn",
          token: "n_UFyBWoGvHcHULIoPSOsfxBkuaR",
        },
      ],
    },
  ]);

  const dialogRef = ref(null);
  const formRef = ref(null);
  const ruleForm = ref({
    mcr_name: '',  // 路线名称
    time: [],  // 运营时间
    mcr_people_money: '',  // 接车单价
    mcr_chartered_money: '',  // 包车单价
    mcr_tel: '',  // 咨询电话
    mcr_route_desc: '',  // 路线描述
    mcr_rule_desc: '',  // 规则说明
  });
  const rules = reactive({
    mcr_name: [
      { required: true, message: "请输入路线名称", trigger: "blur", },
    ],
    time: [
      { required: true, message: "请选择运营时间", trigger: "change", },
    ],
    mcr_people_money: [
      { required: true, message: "请输入接车单价", trigger: "blur", },
    ],
    mcr_chartered_money: [
      { required: true, message: "请输入包车单价", trigger: "blur", },
    ],
    mcr_tel: [
      { required: true, message: "请输入咨询电话", trigger: "blur", },
    ],
    mcr_route_desc: [
      { required: true, message: "请输入路线描述", trigger: "blur", },
    ],
    mcr_rule_desc: [
      { required: true, message: "请输入规则说明", trigger: "blur", },
    ],
  });
  const currentRow = ref('');  // 当前编辑行数据
  const infoDialogRef = ref(null);  // 详情对象
  const detailInfo = ref('');  // 详情数据
  /**
   * 
   * 新增、编辑路线
   * 
   * **/
  const openDialog = (row) => {
    staffKeywords.value = '';
    manageList.value = [];
    if (row.mcr_id) {
      //编辑
      currentRow.value = row;
      const data = JSON.parse(JSON.stringify(row));
      onfirmManage.value = data.responsible_user;
      ruleForm.value.mcr_name = data.mcr_name;
      ruleForm.value.time = [data.mcr_run_stime, data.mcr_run_etime];
      ruleForm.value.mcr_people_money = data.mcr_people_money;
      ruleForm.value.mcr_chartered_money = data.mcr_chartered_money;
      ruleForm.value.mcr_tel = data.mcr_tel;
      ruleForm.value.mcr_route_desc = data.mcr_route_desc;
      ruleForm.value.mcr_rule_desc = data.mcr_rule_desc;
    } else {
      // 新增
      currentRow.value = '';
      onfirmManage.value = [];
      ruleForm.value = {
        mcr_name: '',
        time: [],
        mcr_people_money: '',
        mcr_chartered_money: '',
        mcr_tel: '',
        mcr_route_desc: '',
        mcr_rule_desc: '',
      };
    }
    dialogRef.value.show();
  };
  /**
   * 
   * 新增、编辑路线确认提交
   * 
   * **/
  const handleSure = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        const data = JSON.parse(JSON.stringify(ruleForm.value));
        // if (!checkStr(data.mcr_tel, "phone")) {
        //   ElMessage.warning("咨询电话格式错误！");
        //   dialogRef.value.isLoading = false;
        //   return false;
        // }
        // 处理负责人
        let responsibleArr = [];
        if (onfirmManage.value.length > 0) {
          onfirmManage.value.forEach(item => {
            responsibleArr.push(item.u_id);
          })
        } else {
          ElMessage.error('请选择负责人！');
          return false;
        }

        let parmas = {
          mcr_name: data.mcr_name,
          mcr_run_stime: data.time[0],
          mcr_run_etime: data.time[1],
          mcr_people_money: data.mcr_people_money,
          mcr_chartered_money: data.mcr_chartered_money,
          mcr_tel: data.mcr_tel,
          mcr_route_desc: data.mcr_route_desc,
          mcr_rule_desc: data.mcr_rule_desc,
          mcr_responsible_uid: responsibleArr,
        };
        let url = "";
        if (currentRow.value.mcr_id) {
          // 编辑
          url = "editCarmeet";
          parmas["mcr_id"] = currentRow.value.mcr_id;
        } else {
          // 新增
          url = "addCarmeet";
        }
        // console.log("ruleForm.value", data, parmas);
        dialogRef.value.isLoading = true;
        VipApi[url](parmas).then((res) => {
          dialogRef.value.isLoading = false;
          const text = currentRow.value.mcr_id ? "修改" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`路线${text}成功！`);
            dialogRef.value.close();
            tableRef.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
    });
  };

  const staffKeywords = ref('');  // 搜索员工
  const manageList = ref([]);  //  搜索员工成功数据
  const onfirmManage = ref([]);  // 选择员工数据
  /**
   * 
   * 搜索员工
   * 
   * **/
  const searchManage = (query) => {
    BasicApi.searchUser({ keywords: query, }).then(res => {
      if (res.Code == 200) {
        manageList.value = res.Data;
      }
    })
  }
  /**
  * 
  * 选择员工
  * 
  * **/
  const changeManage = (e) => {
    if (manageList.value) {
      const row = manageList.value.filter(res => {
        if (res.u_id == e) {
          return res;
        }
      })
      if (row && row[0]) {
        onfirmManage.value.push(row[0]);
        onfirmManage.value = unique(onfirmManage.value);
      }
    }
  }
  /**
  * 
  * 删除已选员工
  * 
  * **/
  const delManage = (i) => {
    onfirmManage.value.splice(i, 1);
  }
  /**
  * 
  * 修改状态
  * 
  * **/
  const statusChange = (row) => {
    VipApi.editCarmeetStatuso({ mcr_id: row.mcr_id, }).then(res => {
      if (res.Code == 200) {
        ElMessage.success('状态修改成功！');
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
  /**
  * 
  * 详情按钮
  * 
  * **/
  const onDetails = (row) => {
    VipApi.getCarmeetInfo({ mcr_id: row.mcr_id, }).then(res => {
      if (res.Code == 200) {
        detailInfo.value = res.Data;
        infoDialogRef.value.show();
      } else {
        ElMessage.error(res.Message);
      }
    })
  }

  onMounted(() => {
    tableRef.value.tableLoad();
  });
</script>
<style lang="scss">
  .carmeet {
    font-family: "Source Han Sans CN";

    .s-m-r {
      margin-right: 10px;
    }

    .content {
      padding: 15px 20px 20px;
    }

    .carmeet-add-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }

      .contents {
        -webkit-line-clamp: 1;
        color: var(--text-color);
        flex-wrap: wrap;
        margin-top: 10px;
      }

      .active-skin {
        color: var(--theme-color) !important;
        background-color: var(--search-bg-color) !important;
        max-width: 140px;
        display: flex;
        justify-content: space-between;
        margin: 0 10px 10px 0;
        padding: 0 7px;
        border-radius: 4px;
        border: 1px solid var(--theme-color);
        line-height: 24px;
      }
    }

    .infor-title {
      color: #5D6066;
      width: 80px;
      text-align: left;
      margin-bottom: 10px;
    }

    .infor-content {
      color: #262C30;
    }

    .describe-title {
      margin: 25px 0 10px 0;
      color: #262C30;
      font-size: 16px;
      font-weight: bold;
    }

    .rule-text {
      border: none;
      font-family: Source Han Sans CN;
      font-size: 14px;
      width: 100%;
    }
  }
</style>